
import Footer from "../components/Footer/Footer"
import Header from "../components/Header/Header"
import Hero from "../components/Hero/Hero"
import Metrics from "../components/Metrics/Metrics"
import Story from "../components/Story/Story"
import './home.css'


const Home = ()=> {




return (
    <>





<div class="relative">






    
    <div class="sticky top-0 h-screen flex flex-col items-center justify-center bg-gradient-to-b from-green-200 to-blue-200">
        
<Header/>
<Hero/>

    </div>
    <div class="sticky top-0 h-screen flex flex-col items-center justify-center bg-gradient-to-b from-indigo-800 to-purple-700 text-white">
<Story/>
    
    </div>
    <div class="sticky top-0 h-screen flex flex-col items-center justify-center bg-gradient-to-b from-orange-400 to-pink-800 text-white">
<Metrics/>

    </div>
    <div class="sticky top-0 h-screen flex flex-col items-center justify-center bg-gradient-to-b from-neutral-400 to-indigo-100 text-black">
<Footer/>
    </div>
</div>



    </>
)


}



export default Home