
import community from '../../../media/community.jpg'


const Footer = () => {

    return (

<div className="container  rounded-3xl p-5 drop-shadow-[0_15px_0px_rgba(0,0,0)] bg-white border-2 border-black ">
            <div className="container">
                <div className="w-full h-full flex items-center justify-center gap-5">

                    <img src={community} alt="" className='w-40 rounded-full' />
                    <div className='flex flex-col gap-5'>

                        <a href="https://t.me/biaoqing_wtf" target='blank'>
                            <button className="rounded-3xl drop-shadow-[0_5px_1px_rgba(0,0,0)] bg-orange-500 border border-black p-5 title text-2xl hover:bg-orange-300 transition-all">
                                Join $BIAO Community
                            </button>
                        </a>

                    </div>

                </div>

            </div>

        </div>
    )


}

export default Footer