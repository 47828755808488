
import logo from '../../../media/logo.gif'
import CopyText from '../CopyText/CopyText'
import metrics from '../../../media/metrics.png'





const Metrics = () => {


    const TokenMetrics = ['100T supply','LP Locked','5% project Tokens','95% Sale Tokens','Ownership Revoked' ]


    const Cards = () => {

        return (
            <>
            
            {
                TokenMetrics?.map((data)=> <div className='w-28 h-28 md:w-40 md:h-40  flex flex-col items-center justify-center bg-orange-100  rounded-3xl p-5 drop-shadow-[0_10px_1px_rgba(0,0,0)]  border-2 border-black'>
                <h1 className="honk text-2xl md:text-3xl text-center" >{data}</h1>
            </div> )
            }
            
            </>


        )

    }


    return (

        <div className=" w-full h-full  flex  justify-center p-5" id='metrics'>
            <div className="container  rounded-3xl p-5 md:p-10 drop-shadow-[0_10px_1px_rgba(0,0,0)] bg-white border-2 border-black ">
                <div className="flex flex-col md:flex-row gap-5 w-full items-center">


                    <div className="container p-5 flex flex-col gap-5 md:gap-10 ">

                        <h1 className="honk text-6xl" >The Metrics</h1>

                        <div className='flex gap-5 flex-wrap'>
                            <Cards />
                           

                        </div>

                    </div>
                    <img src={metrics} alt="" className='w-40 md:w-60 funny-animation ' />


                </div>

            </div>


        </div>


    )


}


export default Metrics