import { useState } from "react";
import { IoCopySharp } from "react-icons/io5";
import { FaClipboardCheck } from "react-icons/fa";


const CopyText = ({text}) => {
    const [textToCopy, setTextToCopy] = useState(text);
    const [copied, setCopied] = useState(false);
  
    const handleCopy = async () => {
      try {
        await navigator.clipboard.writeText(textToCopy);
        setCopied(true);
        setTimeout(() => setCopied(false), 2000); // Reset the "copied" state after 2 seconds
      } catch (error) {
        console.error('Failed to copy text:', error);
      }
    };
  
    return (
      <div className="flex gap-2 md:gap-5 md:p-2 p-1 border border-neutral-400 rounded-lg w-max  items-center bg-white shadow-lg">
        <div className="relative w-60 md:w-80 lg:w-full">

        <p className="font-bold text-xs sm:text-sm  w-full md:text-md lg:text-lg  truncate text-ellipsis ">{textToCopy}</p>
        </div>
       {!copied && <IoCopySharp className="text-2xl md:text-3xl  cursor-pointer hover:scale-[120%]"  onClick={handleCopy}/>}
       {copied && <FaClipboardCheck className="text-2xl md:text-3xl  cursor-pointer text-green-500"  onClick={handleCopy}/>}
      </div>
    );
  };
  
  export default CopyText;