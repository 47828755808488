
import logo from '../../../media/logo.gif'
import CopyText from '../CopyText/CopyText'
import biao from '../../../media/biao.png'
import cry from '../../../media/cry.gif'


const Story = () => {
    return (

        <div className=" w-full h-full  flex  justify-center p-5" id='story'>
            <div className="container  rounded-3xl drop-shadow-[0_10px_0px_rgba(0,0,0)] bg-white border-2 border-black p-10 ">
                <div className="flex-col lg:flex-row gap-10 w-full flex items-start">

                    {/* <img src={biao} alt="" className='md:w-60 lg:w-80' /> */}
                    
                    <img src={cry} alt="" className='md:w-60 lg:w-80 w-40 funny-animation ' />

                    <div className="flex flex-col gap-5  rounded-3xl p-5 bg-orange-100 border-2 border-black drop-shadow-[0_10px_0px_rgba(0,0,0)] ">

                        <h1 className="honk text-4xl md:text-6xl" >The Story</h1>

                        <h2 className="title text-md  overflow-y-scroll lg:overflow-visible h-80  lg:h-full  text-black font-sans tracking-wide " >
                            Once upon a time, in the vast expanse of the Solana blockchain, there lived a quirky little meme named Biaoqing. Unlike any other meme, Biaoqing had a mischievous sparkle in his pixelated eyes and a grin that could light up even the darkest corners of the digital realm.

                            As Biaoqing's popularity soared, the denizens of the blockchain began to take notice. They marveled at his ability to evoke laughter and joy with just a single expression. Soon, whispers of a grand plan began to spread among the blockchain community: turning Biaoqing into a token.

                            And so, with a flurry of keystrokes and a dash of blockchain magic, the Biaoqing token was born. With a total supply of 100 trillion tokens, it seemed as if the entire blockchain was buzzing with excitement. Biaoqing himself couldn't contain his glee, bouncing around the digital landscape like a pixelated ping-pong ball.

                            But what truly set the Biaoqing token apart was its unique feature: no trading fees. That's right, every transaction made with Biaoqing came with a guarantee of fee-free trading. It was as if the blockchain had been infused with a dose of comedy, with users trading Biaoqing tokens left and right without a care in the world.

                            As word of the fee-free Biaoqing token spread, traders from far and wide flocked to the Solana chain, eager to get their hands on a piece of the meme magic. The blockchain was ablaze with activity, with Biaoqing tokens flying back and forth faster than a pixelated rocket.

                            But amidst all the chaos and excitement, Biaoqing remained the same lovable meme he had always been. With a wink and a nod, he watched as his token brought laughter and joy to the blockchain community, proving once and for all that sometimes, the best things in life really are fee-free.</h2>
                    </div>


                </div>

            </div>


        </div>


    )


}


export default Story