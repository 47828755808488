
import logo from '../../../media/logo.gif'
import CopyText from '../CopyText/CopyText'
import stick from '../../../media/1.webp'
import stick2 from '../../../media/2.webp'
import stick3 from '../../../media/3.webp'
import stick4 from '../../../media/4.webp'
import stick5 from '../../../media/5.webp'
import stick6 from '../../../media/6.webp'
import stick7 from '../../../media/7.webp'

import solana from '../../../media/solana.png'
import rfc from '../../../media/rfc.png'
import dance from '../../../media/dance.gif'

const Hero = () => {


    const Images = () => {

        return (


            <div className='flex w-full flex-wrap items-center justify-center p-2 sm:p-5'>
                <img src={stick} alt="" className='w-16 lg:w-20 rounded-xl' />
                <img src={stick2} alt="" className='w-10 lg:w-20 rounded-xl' />
                <img src={stick3} alt="" className='w-16 lg:w-20 rounded-xl' />
                <img src={stick4} alt="" className='w-16 lg:w-20 rounded-xl' />
                <img src={stick5} alt="" className='w-16 lg:w-20 rounded-xl' />
                <img src={stick6} alt="" className='w-16 lg:w-20 rounded-xl' />
                <img src={stick7} alt="" className='w-16 lg:w-20 rounded-xl' />

            </div>

        )


    }


    return (

        <div className=" w-full h-full flex  justify-center p-5">
            <div className="container  rounded-3xl  drop-shadow-[0_10px_1px_rgba(0,0,0)] bg-white border-2 border-black p-10 paper ">
                <div className=" md:gap-5 gap-2 w-full items-center flex flex-col justify-center">

                    <div className='flex items-center justify-center flex-col md:flex-row'>
                        <img src={logo} alt="" className='w-20 md:w-40 sm:w-32 lg:w-60 '/>
                        <h1 className="honk lg:text-6xl text-3xl text-center " >$BIAO ! Solana's Most Indispensible Meme !</h1>
                    </div>

                    <div className='flex gap-5'>
                                <img src={solana} alt="" className='w-12 md:w-16 lg:w-20' />
                                <img src={rfc} alt="" className='w-12 md:w-16 lg:w-20 rounded-full' />
                            </div>


                    <div className='flex flex-col gap-5 md:gap-10 xl:gap-20 items-center'>

                        <CopyText text={'HcFcLiWECa1nqqQ9Ae8zbNuhphh4nP3KxFw7vxE4cQiZ   '} />

                        <Images />
                        <img src={dance} alt="" className='absolute right-0 bottom-0 w-20 md:w-40 sm:w-32  lg:w-60' />
                    </div>


                </div>

            </div>


        </div>


    )


}


export default Hero