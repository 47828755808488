import logo from "./logo.svg";
import "./App.css";
import Home from "./containers/Home/Home";
import Header from "./containers/components/Header/Header";
import { Helmet } from "react-helmet";

function App() {
  return (
    <div className="w-full min-h-screen  ">
      {/* <Helmet>
        <title>$BIAO ! Solana's Most Indispensible Meme !</title>
        <meta
          name="description"
          content="Join the meme revolution with BioAQing! Laugh, share, and HODL the funniest memes around. Get in on the joke and let's take the meme world by storm!"
        />
        <meta property="og:title" content="Bioaqing on SOL" />
        <meta
          property="og:description"
          content="Join the meme revolution with BioAQing! Laugh, share, and HODL the funniest memes around. Get in on the joke and let's take the meme world by storm!"
        />
        <meta
          property="og:image"
          content="https://i.ibb.co/tq7xPRY/sharing.png"
        />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
      </Helmet> */}
      <Home />
    </div>
  );
}

export default App;
