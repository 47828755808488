
import { BsTelegram } from "react-icons/bs";
import { FaSquareXTwitter } from "react-icons/fa6";
import { TbCurrencySolana } from "react-icons/tb";



const Header = () => {


    const scrollToSection = (id) => {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };


    const Socials = () => {
        return (

            <div className="flex items-center gap-5">
                <a href="https://t.me/biaoqing_wtf" target="blank">
                    <BsTelegram className="text-lg lg:text-5xl md:text-3xl sm:text-xl transition-all hover:text-neutral-800 text-red-500 cursor-pointer " />
                </a>
                <a href="https://x.com/biaoqing_wtf?s=09" target="blank">

                    <FaSquareXTwitter className="text-lg lg:text-5xl sm:text-xl md:text-3xl  transition-all hover:text-neutral-800 text-red-500 cursor-pointer " />
                </a>

                <a href="https://solscan.io/token/HcFcLiWECa1nqqQ9Ae8zbNuhphh4nP3KxFw7vxE4cQiZ" target="blank">


                <TbCurrencySolana className="text-lg lg:text-5xl sm:text-xl md:text-3xl transition-all hover:text-neutral-800 text-red-500 cursor-pointer " />
                </a>
            </div>
        )
    }


    const Navigation = () => {
        return (
            <div className="flex gap-1 md:gap-5">
                <h1 className="title lg:text-4xl xl:text-4xl md:text-lg text-xs hover:text-neutral-800 text-red-500  transition-all cursor-pointer" onClick={() => scrollToSection('story')} >The Story</h1>
                <h1 className="title lg:text-4xl xl:text-4xl md:text-lg text-xs hover:text-neutral-800 text-red-500  transition-all cursor-pointer" onClick={() => scrollToSection('metrics')} >Metrics</h1>
            </div>

        )
    }



    return (

        <div className=" w-full  flex items-center justify-center p-5">
            <div className="container  rounded-3xl p-5 drop-shadow-[0_10px_1px_rgba(0,0,0)] bg-white border-2 border-black ">
                <div className="flex lg:gap-10 gap-5 w-full items-center justify-center">
                    <h1 className="honk lg:text-5xl text-lg" >BiaoQing</h1>
                    <div className="flex justify-between w-full items-center">
                        <Navigation />
                        <Socials />
                    </div>
                </div>
            </div>
        </div>
    )
}


export default Header